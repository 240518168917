import { config } from "./storageHelper";

interface Logger {
    log: (message?: any, ...optionalParams: any[]) => void;
    error: (message?: any, ...optionalParams: any[]) => void;
    warn: (message?: any, ...optionalParams: any[]) => void;
    clear: () => void;
}

const logger: Logger = {
    log: function (message?: any, ...optionalParams: any[]): void {
        if (config.debug) {
            switch (optionalParams.length) {
                case 0:
                    console.log("LOG: \n" + message);
                    break;
                case 1:
                    console.log("LOG: \n" + message, optionalParams[0]);
                    break;
                case 2:
                    console.log(
                        "LOG: \n" + message,
                        optionalParams[0],
                        optionalParams[1]
                    );
                    break;
                default:
                    console.log("LOG: \n" + message, optionalParams);
                    break;
            }
        }
    },
    error: function (message?: any, ...optionalParams: any[]): void {
        switch (optionalParams.length) {
            case 0:
                console.log("ERROR: \n" + message);
                break;
            case 1:
                console.log("ERROR: \n" + message, optionalParams[0]);
                break;
            case 2:
                console.log(
                    "ERROR: \n" + message,
                    optionalParams[0],
                    optionalParams[1]
                );
                break;
            default:
                console.log("ERROR: \n" + message, optionalParams);
                break;
        }
    },
    warn: function (message?: any, ...optionalParams: any[]): void {
        switch (optionalParams.length) {
            case 0:
                console.log("WARN: \n" + message);
                break;
            case 1:
                console.log("WARN: \n" + message, optionalParams[0]);
                break;
            case 2:
                console.log("WARN: \n" + message, optionalParams[0], optionalParams[1]);
                break;
            default:
                console.log("WARN: \n" + message, optionalParams);
                break;
        }
    },
    clear: function (): void {
        console.clear();
    },
};

export default logger;
