import logger from "./helpers/logHelper";

interface Task {
    resolve: (reason?: any) => void;
    reject: (reason?: any) => void;
    promise: Promise<string>;
}

interface Native {
    getVersion: () => number;
    getVersionName: () => string;
    getPlatform: () => string;
    getTagId: (taskId: string) => void;
    setAccessKey: (taskId: string, keyId: number, key: string) => void;
    changePassword: (
        taskId: string,
        keyId: number,
        oldPassword: string,
        newPassword: string
    ) => void;
    read: (
        taskId: string,
        fileNum: number,
        offset: number,
        length: number
    ) => void;
    write: (
        taskId: string,
        fileNum: number,
        data: string,
        offset: number
    ) => void;
}

interface NfcTag {
    id: string;
    techList: string[];
}

const tasks: { [key: string]: Task } = {};
const genTaskId = () => {
    return Math.random().toString().substring(0, 10);
};
const commonGenPromise = (taskId: string) => {
    const task = {} as Task;
    const promise = new Promise<string>(function (resolve, reject) {
        task.resolve = resolve;
        task.reject = reject;
    });
    task.promise = promise;
    tasks[taskId] = task;
    return promise;
};
const nfcTagReadHooks: (((tag: NfcTag) => any) | null)[] = [];
(window as any).onNfcTagRead = (tag: NfcTag) => {
    for (const i of nfcTagReadHooks) {
        if (i) {
            i(tag);
        }
    }
};
(window as any).nativeCallback = (taskId: string, dataStr: string) => {
    logger.log(`task: ${taskId}, data: ${dataStr}`);
    const data = JSON.parse(dataStr);
    const taskObj = tasks[taskId];
    if (data.isSuccess) {
        taskObj.resolve(data);
    } else {
        taskObj.reject(data);
    }
    delete tasks[taskId];
};
const getNative = () => {
    return (window as any).native as Native | undefined;
};
const bridge = (() => {
    const native = getNative();
    if (!native) {
        const failPromise = () =>
            new Promise((resolve, reject) => {
                reject("Native interface is not supported.");
            });
        return {
            getVersion: () => 0,
            getVersionName: () => "UNSUPPORTED",
            getPlatform: () => "web",
            getTagId: () => {
                return failPromise();
            },
            setAccessKey: (keyId: number, key: string) => {
                return failPromise();
            },
            changePassword: (
                keyId: number,
                oldPassword: string,
                newPassword: string
            ) => {
                return failPromise();
            },
            read: (fileNum: number, offset: number, length: number) => {
                return failPromise();
            },
            write: (fileNum: number, data: string, offset: number) => {
                return failPromise();
            },
            onNfcTagRead: (func: (tag: NfcTag) => any) => {
                return -1;
            },
            clearNfcTagRead: (func: (tag: NfcTag) => any) => { },
        };
    } else {
        return {
            getVersion: () => native.getVersion(),
            getVersionName: () => native.getVersionName(),
            getPlatform: () => native.getPlatform(),
            getTagId: () => {
                try {
                    const taskId = genTaskId();
                    native.getTagId(taskId);
                    return commonGenPromise(taskId);
                } catch (e) {
                    return Promise.reject(e);
                }
            },
            setAccessKey: (keyId: number, key: string) => {
                try {
                    const taskId = genTaskId();
                    native.setAccessKey(taskId, keyId, key);
                    return commonGenPromise(taskId);
                } catch (e) {
                    return Promise.reject(e);
                }
            },
            changePassword: (
                keyId: number,
                oldPassword: string,
                newPassword: string
            ) => {
                try {
                    const taskId = genTaskId();
                    native.changePassword(taskId, keyId, oldPassword, newPassword);
                    return commonGenPromise(taskId);
                } catch (e) {
                    return Promise.reject(e);
                }
            },
            read: (fileNum: number, offset: number, length: number) => {
                try {
                    const taskId = genTaskId();
                    native.read(taskId, fileNum, offset, length);
                    return commonGenPromise(taskId);
                } catch (e) {
                    return Promise.reject(e);
                }
            },
            write: (fileNum: number, data: string, offset: number) => {
                try {
                    const taskId = genTaskId();
                    native.write(taskId, fileNum, data, offset);
                    return commonGenPromise(taskId);
                } catch (e) {
                    return Promise.reject(e);
                }
            },
            onNfcTagRead: (func: (tag: NfcTag) => any) => {
                try {
                    return nfcTagReadHooks.push(func);
                } catch (e) {
                    return Promise.reject(e);
                }
            },
            clearNfcTagRead: (id: number) => {
                try {
                    nfcTagReadHooks[id] = null;
                } catch (e) {
                    // ignore
                }
            },
        };
    }
})();

export default bridge;
