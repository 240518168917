export const config = (await (await fetch("config.json")).json()) as {
    base_url: string;
    debug: boolean;
};

// Storage keys
export enum StorageKey {
    StocktakeTicket = "stocktakeTicket",
    SystemParameters = "systemParameters",
    StocktakeStartTime = "stocktakeStartTime",
    StocktakeAssetsList = "stocktakeAssetsList",
    StocktakeAsset = "stocktakeAsset",
    Platform = "platform",
    FactoryCoordinates = "factoryCoordinates",
    StocktakeTicketId = "stocktakeTicketId",
    ScanningAssetIdList = "scanningAssetIdList",
    Token = "token",
    ProjectId = "projectId",
    IsWrongLocation = "isWrongLocation",
    NFCLocation = "nfcLocation",
}

// Set data in localStorage
export const setLocalStorageData = (key: StorageKey, data: any): void => {
    localStorage.setItem(key, JSON.stringify(data));
};

// Check if data exists in localStorage
export const hasLocalStorageData = (key: StorageKey): boolean => {
    return Boolean(localStorage.getItem(key));
}

// Get data from localStorage
export const getLocalStorageData = (key: StorageKey): any => {
    const data = localStorage.getItem(key);
    try {
        return data ? JSON.parse(data) : null;
    } catch (e) {
        removeLocalStorageData(StorageKey.Token);
        window.location.href = "/";
    }
};

// Get data from localStorage with type
export const getTypeLocalStorageData = <T>(key: StorageKey): T | undefined => {
    const data = localStorage.getItem(key);
    try {
        return data ? (JSON.parse(data) as T) : undefined;
    } catch (e) {
        removeLocalStorageData(StorageKey.Token);
        window.location.href = "/";
    }
    return undefined;
};

// Get all data from localStorage
export const getAllLocalStorageData = (): any => {
    return {
        stocktakeTicket: getLocalStorageData(StorageKey.StocktakeTicket),
        systemParameters: getLocalStorageData(StorageKey.SystemParameters),
        assetList: getLocalStorageData(StorageKey.StocktakeAssetsList),
        stocktakeStartTime: getLocalStorageData(StorageKey.StocktakeStartTime),
        platform: getLocalStorageData(StorageKey.Platform),
        factoryCoordinates: getLocalStorageData(StorageKey.FactoryCoordinates),
        stocktakeTicketId: getLocalStorageData(StorageKey.StocktakeTicketId),
        scanningAssetIdList: getLocalStorageData(StorageKey.ScanningAssetIdList),
        token: getLocalStorageData(StorageKey.Token),
        projectId: getLocalStorageData(StorageKey.ProjectId),
        stocktakeTicketAsset: getLocalStorageData(StorageKey.StocktakeAsset),
        isWrongLocation: getLocalStorageData(StorageKey.IsWrongLocation),
    };
};

// Remove data from localStorage
export const removeLocalStorageData = (key: StorageKey): void => {
    localStorage.removeItem(key);
};

// Clear all data from localStorage
export const clearLocalStorageData = (): void => {
    localStorage.clear();
};

