import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";

export default function AlertDialog(props: {
    title?: string;
    message?: string;
    show?: boolean;
}) {
    return (
        <div>
            <Dialog id="alert-dialog" open={props.show || false}>
                {props.title && (
                    <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                )}
                <DialogContent>
                    {props.message && (
                        <DialogContentText id="alert-dialog-message">
                            {props.message}
                        </DialogContentText>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}
