import { config, getLocalStorageData, removeLocalStorageData, setLocalStorageData, StorageKey } from "./storageHelper";
import logger from "./logHelper";

const ApiPrefix = "/api/Mobile/";

export interface ApiResponse {
    ok: boolean;
    status: number;
    title: string;
}

export interface DataStructBase {
    isDisabled: boolean;
    createTime: string;
    createBy?: string;
    updateTime?: string;
    updateBy?: string;
    remark?: string;
}

export interface SearchParamsBaseModel {
    page?: number;
    pageSize?: number;
    sortColumn?: string;
    sortDirection?: "ASC" | "DESC";
}

export interface ApiResponseT<T> extends ApiResponse {
    count: number;
    result: T;
}

const getDefaultRequestInit = (init?: RequestInit) => {
    if (!init) {
        init = {};
    }
    if (!init.headers) {
        init.headers = {};
    }
    (init.headers as Record<string, string>)["Accept"] = "application/json";
    const token = getLocalStorageData(StorageKey.Token);
    if (token) {
        (init.headers as Record<string, string>)[
            "Authorization"
        ] = `Bearer ${token}`;
    }
    return init as RequestInit;
};

export const RawRequest = async (
    url: string,
    init?: RequestInit
): Promise<Response> => {
    const lInit = getDefaultRequestInit(init);
    logger.log(
        [
            "URL=" + config.base_url + ApiPrefix + url,
            "Method=" + init?.method,
            "Headers=" + JSON.stringify(init?.headers),
            "Body=" +
            ((JSON.stringify(init?.body) ?? "").indexOf(":") > 0
                ? JSON.stringify(init?.body)
                : `{${Array.from(
                    ((init?.body as FormData | URLSearchParams) ?? []).entries()
                )
                    .map(([key, value]) => `${key}: "${value}"`)
                    .join(",")}}`),
        ].join("\n")
    );
    const result = (await fetch(
        config.base_url + ApiPrefix + url,
        lInit
    )) as Response;
    if (result.status === 401) {
        removeLocalStorageData(StorageKey.Token);
        window.location.href = "/";
    }
    logger.log(
        [
            "URL=" + config.base_url + ApiPrefix + url,
            "Response=" + result.status,
        ].join("\n")
    );
    return result;
};

export const Request = async (
    url: string,
    init?: RequestInit
): Promise<[ApiResponse | null, Response]> => {
    logger.log(
        [
            "URL=" + config.base_url + ApiPrefix + url,
            "Method=" + init?.method,
            "Headers=" + JSON.stringify(init?.headers),
            "Body=" +
            ((JSON.stringify(init?.body) ?? "").indexOf(":") > 0
                ? JSON.stringify(init?.body)
                : `{${Array.from(
                    ((init?.body as FormData | URLSearchParams) ?? []).entries()
                )
                    .map(([key, value]) => `${key}: "${value}"`)
                    .join(",")}}`),
        ].join("\n")
    );
    let fetchResult: Response = new Response();
    let json: ApiResponse | null = null;
    try {
        fetchResult = await fetch(config.base_url + ApiPrefix + url, init);
        if (fetchResult.status === 401) {
            removeLocalStorageData(StorageKey.Token);
            window.location.href = "/";
        }
        json = await fetchResult.json();
    } catch (e) {
        // may convert to json error
    }
    logger.log(
        [
            "URL=" + config.base_url + ApiPrefix + url,
            "Response=" + JSON.stringify(json),
        ].join("\n")
    );
    return [json, fetchResult];
};

export const RequestPath = (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    return Request(url, lInit);
};

export const RequestFormData = (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    (lInit.headers as Record<string, string>)["Content-Type"] =
        "multipart/form-data";
    return Request(url, lInit);
};

export const RequestUrlEncoded = (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    (lInit.headers as Record<string, string>)["Content-Type"] =
        "application/x-www-form-urlencoded";
    return Request(url, lInit);
};

export const RequestJson = async (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    (lInit.headers as Record<string, string>)["Content-Type"] =
        "application/json";
    return Request(url, lInit);
};

export const login = (
    id: string,
    password: string,
    clientTime: string,
    resultCallback?: Function,
    errorCallback?: Function,
    finallyCallback?: Function
) => {
    const result = RequestUrlEncoded("Session/Login", {
        method: "POST",
        body: new URLSearchParams({
            projectId: id,
            password: password,
            clientTime: clientTime,
        }),
    })
        .catch((e) => {
            if (errorCallback) {
                errorCallback(e);
            }
        })
        .then((res) => {
            if (res && res[0]) {
                const token = (res[0] as any).accessToken;
                if (token) {
                    setLocalStorageData(StorageKey.Token, token);
                    res[0].ok = true;
                    res[0].status = 200;
                }
            }
            if (resultCallback) {
                resultCallback(res);
            }
        })
        .finally(() => {
            if (finallyCallback) {
                finallyCallback();
            }
        });
};

export const logout = (
    resultCallback?: Function,
    errorCallback?: Function,
    finallyCallback?: Function
) => {
    const result = RequestUrlEncoded("Session/Logout", {
        method: "POST",
    })
        .catch((e) => {
            if (errorCallback) {
                errorCallback(e);
            }
        })
        .then((res) => {
            if (res && res[0] && res[0].ok) {
                removeLocalStorageData(StorageKey.Token);
            }
            if (resultCallback) {
                resultCallback(res);
            }
        })
        .finally(() => {
            if (finallyCallback) {
                finallyCallback();
            }
        });
};

export const verifyLocationLatLng = (
    lat: number,
    lng: number,
    resultCallback?: Function,
    errorCallback?: Function,
    finallyCallback?: Function
) => {
    const result = RequestUrlEncoded("Session/LocationVerification", {
        method: "POST",
        body: new URLSearchParams({
            lat: String(lat),
            lng: String(lng),
            deviceType: getLocalStorageData(StorageKey.Platform) ?? "",
        }),
    })
        .catch((e) => {
            if (errorCallback) {
                errorCallback(e);
            }
        })
        .then((res) => {
            if (resultCallback) {
                resultCallback(res);
            }
        })
        .finally(() => {
            if (finallyCallback) {
                finallyCallback();
            }
        });
};

export const verifyLocationNFC = (
    nfcTagId: string,
    resultCallback?: Function,
    errorCallback?: Function,
    finallyCallback?: Function
) => {
    const result = RequestUrlEncoded("Session/LocationVerification", {
        method: "POST",
        body: new URLSearchParams({
            lat: String(-1),
            lng: String(-1),
            nfcTagId: nfcTagId,
            deviceType: getLocalStorageData(StorageKey.Platform) ?? "",
        }),
    })
        .catch((e) => {
            if (errorCallback) {
                errorCallback(e);
            }
        })
        .then((res) => {
            if (resultCallback) {
                resultCallback(res);
            }
        })
        .finally(() => {
            if (finallyCallback) {
                finallyCallback();
            }
        });
};

export const getProjectId = (
    resultCallback?: Function,
    errorCallback?: Function,
    finallyCallback?: Function
) => {
    const result = RequestPath("Session/GetCurrentUsername", {
        method: "GET",
    })
        .catch((e) => {
            if (errorCallback) {
                errorCallback(e);
            }
        })
        .then((res) => {
            if (resultCallback) {
                resultCallback(res);
            }
        })
        .finally(() => {
            if (finallyCallback) {
                finallyCallback();
            }
        });
};

export const getMetaData = (
    resultCallback?: Function,
    errorCallback?: Function,
    finallyCallback?: Function
) => {
    const result = RequestPath("Session/GetMetadata", {
        method: "GET",
    })
        .catch((e) => {
            if (errorCallback) {
                errorCallback(e);
            }
        })
        .then((res) => {
            if (resultCallback) {
                resultCallback(res);
            }
        })
        .finally(() => {
            if (finallyCallback) {
                finallyCallback();
            }
        });
};

export const getAssets = (
    id?: string,
    resultCallback?: Function,
    errorCallback?: Function,
    finallyCallback?: Function
) => {
    const result = RequestPath("Assets/" + (id ?? ""), {
        method: "GET",
    })
        .catch((e) => {
            if (errorCallback) {
                errorCallback(e);
            }
        })
        .then((res) => {
            if (resultCallback) {
                resultCallback(res);
            }
        })
        .finally(() => {
            if (finallyCallback) {
                finallyCallback();
            }
        });
};

export const getStocktakeTicketAssets = (
    id?: string,
    resultCallback?: Function,
    errorCallback?: Function,
    finallyCallback?: Function
) => {
    const result = RequestPath("StocktakeTicketAssets/" + (id ?? ""), {
        method: "GET",
    })
        .catch((e) => {
            if (errorCallback) {
                errorCallback(e);
            }
        })
        .then((res) => {
            if (resultCallback) {
                resultCallback(res);
            }
        })
        .finally(() => {
            if (finallyCallback) {
                finallyCallback();
            }
        });
};

export const submitAssets = async (data: any) => {
    const result = await RequestJson("Stocktake/SubmitAssets", {
        method: "POST",
        body: JSON.stringify([data]),
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const submitOperatorName = async (operatorName: string) => {
    const result = await RequestUrlEncoded("Stocktake/Submit", {
        method: "POST",
        body: new URLSearchParams({
            operatorName: operatorName,
        }),
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const getPictures = async (id: number) => {
    const result = await RawRequest("Pictures/" + id, {
        method: "GET",
    });
    return result;
};

export const getThumbnail = async (id: number) => {
    const result = await RawRequest("Pictures/Thumbnail/" + id, {
        method: "GET",
    });
    return result;
};

export const uploadPictures = async (
    stocktakeTicketAssetId: string,
    pictureblobs: Blob[]
) => {
    const data = new FormData();
    data.append("StocktakeTicketAssetId", stocktakeTicketAssetId);
    pictureblobs.forEach((blob, index) => {
        data.append("files", blob, new Date().getTime() + "_" + index + ".jpg");
    });
    const result = await RequestPath("Pictures/AddByStocktakeAsset", {
        method: "POST",
        body: data,
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const updatePictures = async (
    pictureId: number,
    stocktakeTicketAssetId: string,
    pictureblob: Blob
) => {
    const data = new FormData();
    data.append("StocktakeTicketAssetId", stocktakeTicketAssetId);
    data.append("files", pictureblob, new Date().getTime() + ".jpg");
    const result = await RequestPath("Pictures/UpdateByStocktakeAsset/" + pictureId, {
        method: "POST",
        body: data,
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const removePictures = async (pictureId: number) => {
    const result = await RequestPath("Pictures/DeleteByStocktakeAsset/" + pictureId, {
        method: "DELETE",
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const addOrUpdateImagebySequenceNumber = async (
    sequenceNumber: number,
    stocktakeTicketAssetId: string,
    pictureblob: Blob
) => {
    const data = new FormData();
    data.append("SequenceNumber", String(sequenceNumber));
    data.append("StocktakeTicketAssetId", stocktakeTicketAssetId);
    data.append("Pic", pictureblob, Date.now() + ".jpg");
    const result = await RequestPath("Pictures/AddOrUpdateImagebySequenceNumber", {
        method: "POST",
        body: data,
    });
    return result[0] ?? result[1] ?? { ok: false };
};
