import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useNetworkStatus from "../../utils/networkStatus";
import nativeBridge from "../../utils/nativebridge";
import { getLocalStorageData, hasLocalStorageData, setLocalStorageData, StorageKey } from "../../utils/helpers/storageHelper";

import Banner from "../banner/banner";
import AlertDialog from "../dialogs/alertDialog";

export function isWebPlatform() {
    if (hasLocalStorageData(StorageKey.Platform)) {
        return getLocalStorageData(StorageKey.Platform) === "web";
    }
    const nativePlatform = nativeBridge.getPlatform();
    setLocalStorageData(StorageKey.Platform, nativePlatform);
    return nativePlatform === "web";

}

export function setBanner(path: string) {
    const noBackPage = ["NfcScanner", "AssetsList", "AssetsCompleted"];
    const useBack = !noBackPage.includes(path.split("/").pop() as string);

    document
        .getElementById("header-back")
        ?.setAttribute("style", `display: ${useBack ? "block" : "none"}`);
}

export default function App() {
    useEffect(() => {
        const root = document.getElementById("root");
        if (root) {
            root.style.maxHeight = "100vh";
        }
    }, []);

    const { t, i18n } = useTranslation();
    const { isOnline } = useNetworkStatus();

    if (!hasLocalStorageData(StorageKey.Token)) {
        window.location.href = "/";
    }


    return (
        <div className="App">
            <header className="App-header">
                <Banner />
            </header>
            <Outlet />
            <AlertDialog
                show={!isOnline}
                title={t("title_internet_connection_lost")}
                message={t("msg_internet_no_connection")}
            />
        </div>
    );
}
