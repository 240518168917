import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import { logout } from "../../utils/helpers/apiHelper";

import "./banner.scss";

import iconBack from "../../assets/btn_back.png";
import iconSignOut from "../../assets/icon_signout.png";

export default function Banner() {
    const navigate = useNavigate();

    const [showLogoutDialog, setShowLogoutDialog] = useState(false);
    const [isBacking, setIsBacking] = useState(false);

    const back = () => {
        if (isBacking) {
            return;
        }

        setIsBacking(true);
        window.history.back();
        setTimeout(() => {
            setIsBacking(false);
        }, 500);
    };
    const signOut = () => {
        logout();
        navigate("/Login");
    };

    return (
        <div className="single-row banner">
            <img
                id="header-back"
                src={iconBack}
                onClick={back}
                className="link"
                tabIndex={0}
            />
            <div className="empty" />
            <img
                id="header-sign-out"
                src={iconSignOut}
                onClick={() => setShowLogoutDialog(true)}
                className="link"
                tabIndex={0}
            />
            <Dialog
                open={showLogoutDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Trans>msg_confirm_logout</Trans>
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setShowLogoutDialog(false)}>
                        <Trans>btn_cancel</Trans>
                    </Button>
                    <Button onClick={signOut} autoFocus>
                        <Trans>btn_confirm</Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
