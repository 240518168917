import "./androidToast.scss";

export default function AndroidToast(props : {
    toastMessage: string,
    toastAlpha?: number,
}) {
    return (
        <div
            className={"android-toast toast"}
            style={{
                opacity: props.toastAlpha ?? 1,
                zIndex: (props.toastAlpha ?? 1) > 0 ? 9999 : -1,
            }}
        >
            {props.toastMessage}
        </div>
    );
}