import { useTranslation } from "react-i18next";

import AndroidToast from "../components/androidToast/androidToast";

export default function Loading() {
    const { t, i18n } = useTranslation();

    const message = t("msg_system_loading");

    return (
        <div className="Loading">
            <h1 hidden>{message}</h1>
            <AndroidToast toastMessage={message} />
        </div>
    );
}
